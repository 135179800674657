import React from "react";
import { Header } from "../components/Header";
import { Overlay } from "../components/Overlay";
import { SEO } from "../components/SEO";
import { TermsAndConditions } from "../components/TermsAndConditions";

const Page = () => {
    return (
        <>
            <SEO title={"Watch to Win"} lang={"en-au"} meta={[]} />
            <Overlay />
            <Header />
            <TermsAndConditions closeTerms={() => {}} />
        </>
    );
};

export default Page;
